body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html ::-webkit-scrollbar {
  width: 16px;
  background-color: var(--chakra-colors-blackAlpha-50);
}
html ::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #cccccc;
}

/* ripple btn */
.ripple:not([disabled]) {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:not([disabled]):after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:not([disabled]):active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.ripple:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ripple btn */

/* animation router  */

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}
/* animation router  */
/* #nprogress */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #007bff;
  z-index: 2147483647;
  top: 57px;
  left: 0;
  width: 100%;
  height: 3px;
  position: absolute;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  opacity: 1;
}

#nprogress .spinner {
  position: absolute;
  display: block;
  z-index: 2147483647;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #007bff;
  border-left-color: #007bff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 0.4s linear infinite;
  animation: nprogress-spinner 0.4s linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* #nprogress */

/* calendar */

.react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  border: none !important;
}

/* calendar */

/* hide on print*/
@media print {
  .display-print-none {
    display: none !important;
  }

  .display-print-block {
    display: block !important;
  }
}



/* hide on print*/


body.chakra-ui-dark .react-calendar {
  background: #1a202c !important;
}

.react-calendar__tile:disabled {
  background-color: #855050 !important;
  cursor: not-allowed !important;
}

body.chakra-ui-light .react-calendar {
  background: #ddd !important;
}


.css-select-8888 {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  background: inherit;
  padding-bottom: 1px;
  line-height: var(--chakra-lineHeights-normal);
  font-size: var(--chakra-fontSizes-sm);
  -webkit-padding-start: var(--chakra-space-3);
  padding-inline-start: var(--chakra-space-3);
  -webkit-padding-end: 2rem;
  padding-inline-end: 2rem;
  height: var(--chakra-sizes-8);
  border-radius: 0px;
  border: 1px solid;
  border-color: inherit;
}